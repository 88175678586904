import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import eligo from "../../assets/images/eligo-logo.svg";
import masel from "../../assets/images/masel.svg";
import { RootState } from "../../store";

const Success: React.FC<{ onClose?: Function }> = ({ onClose }) => {
  const { t } = useTranslation();
  const { user, date, time } = useSelector((state: RootState) => state.booking);
  const { facebookPixelId } = useSelector((state: RootState) => state.analytics);
  const [dateTime, setDatetime] = useState("");
  const fbinitRef = React.useRef<boolean>(false);
  const constructDate = (date: string, hour: number, minute: number) => {
    const dayjsDate = dayjs(date).hour(hour).minute(minute);

    return dayjs(dayjsDate);
  };

  useEffect(() => {
    if (!fbinitRef.current && facebookPixelId) {
      let script = document.createElement("script");
      script.id = "fb-script";
      script.innerHTML = `
      !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
        n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
        document,'script','https://connect.facebook.net/en_US/fbevents.js');
        // Insert Your Facebook Pixel ID below. 
        fbq('init', ${facebookPixelId});
        fbq('track', 'SubmitApplication');
      `;
      let noscript = document.createElement("noscript");
      noscript.id = "fb-noscript";
      let img = document.createElement("img");
      img.height = 1;
      img.width = 1;
      img.setAttribute("style", "display: none;");
      img.src = `https://www.facebook.com/tr?id=${facebookPixelId}&amp;ev=PageView&amp;noscript=1`;
      noscript.appendChild(img);

      document.body.appendChild(script);
      document.body.appendChild(noscript);
      fbinitRef.current = true;
    }
    if (date && time) {
      const [hour, minute] = time.split(":");
      let fullDate = constructDate(date, parseInt(hour), parseInt(minute));
      setDatetime(fullDate.format("DD/MM/YYYY HH:mm"));
    }

    return () => {
      let script = document.getElementById("fb-script");
      let noscript = document.getElementById("fb-noscript");
      if (script && noscript) {
        document.body.removeChild(script);
        document.body.removeChild(noscript);
      }
    }
  }, [date, time, facebookPixelId]);

  // const closeWidget = () => {
  //   dispatch(setPage(viewPages.HOME));
  //   dispatch(initWizard(undefined));
  //   if (shownTypes.length === 1) {
  //     dispatch(setStep("date"));
  //   }
  //   dispatch(setToggled(false));
  //   if (type === "modal") {
  //     !onClose || onClose();
  //   }
  // };

  return (
    <div className="success-page">
      {/* <div className="eligo-live">
        <div className="bg-white">
          <img src={eligo} alt="" />
        </div>
        <div className="pulse bg-white"></div>
        <h4 className="mt-1">LIVE</h4>
      </div> */}
      <div className="masel-live">
        <img src={masel} alt="" />
        <div className="live">
          <div className="pulse"></div>
          <span>LIVE</span>
        </div>
      </div>
      <div className="flex flex-col justify-between h-[85%]">
        <Button disabled full className=" border-4">
          {t("SUCCESS.THANKS_MSG")}
        </Button>

        <div className="final-info">
          <div className="text-left">
            <label>{t("INFO.FULLNAME")}</label>
            <p className="mb-1">{user?.name} {user?.surname}</p>
            <label>{t("INFO.PHONE")}</label>
            <p>{user?.phone}</p>
            <label>Email</label>
            <p className="mb-1">{user?.email}</p>
          </div>
          <div className="text-left">
            <label>{t("DATE")}</label>
            <p className="mb-1">{dateTime}</p>
            <label>{t("INFO.ADDITIONAL_NOTES")}</label>
            <p>{user?.notes}</p>
          </div>
        </div>
        <p className="my-2" dangerouslySetInnerHTML={{ __html: t("SUCCESS.CONFIRMATION_FEEDBACK") }} />
      </div>
    </div >

  );
};
export default Success;
