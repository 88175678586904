import React from "react";
import { useTranslation } from "react-i18next";
import { Oval } from "react-loader-spinner";
import { useStopwatch } from "react-timer-hook";
import masel from "../../assets/images/masel.svg";
import Button from "../../components/Button";
import { viewPages } from "../../constants/view";
import { useAppDispatch, useAppSelector } from "../../store";
import { setPage } from "../../store/features/view";
import api from "../../api";
import { initializeMqtt, reset, setError, setWidgetId } from "../../store/features/mqtt";
import useGoogleAnalytics from "../../hooks/useAnalytics";
import { useMqtt } from "../../hooks/useMqtt";

const InstantMeeting: React.FC = () => {
    const { t } = useTranslation();
    const timeOffset = new Date();
    const tenantId = useAppSelector(state => state.view.tenantId);
    const { widgetId, error } = useAppSelector(state => state.mqtt);
    const { seconds, minutes } = useStopwatch({ autoStart: true, offsetTimestamp: timeOffset });
    const dispatch = useAppDispatch();
    const initRef = React.useRef(false);
    const { event } = useGoogleAnalytics();
    useMqtt(event);
    const backToHome = () => {
        dispatch(setPage(viewPages.HOME));
    }

    const bookNow = () => {
        dispatch(setPage(viewPages.BOOKING));
        dispatch(setError(false));

    }

    const initMqtt = React.useCallback(async () => {
        try {
            const availability = await api.MqttService.getAvailability(tenantId);
            dispatch(setWidgetId(availability.widgetId));
            if (availability.data.length > 0) {
                const config = await api.MqttService.registerMqtt();
                dispatch(initializeMqtt({
                    ...config,
                    tenantId
                }));
            }
            else {
                setTimeout(() => {
                    dispatch(setError(true));
                }, 5000);
            }

        } catch (error) {
            dispatch(setError(true));
        }
    }, [dispatch, tenantId]);

    React.useEffect(() => {
        if (tenantId && !initRef.current) {
            initMqtt();
            initRef.current = true;
        }

        return () => {
            dispatch(reset());
        }
    }, [tenantId, widgetId, initMqtt]);

    return <div className="instant-meeting">
        <h2>{t("HELP")}</h2>
        <img src={masel} alt="" />
        <div className="live">
            <div className="pulse"></div>
            <span>LIVE</span>
        </div>
        {/* <div className="live-container">
            <div className="live-logo-container">
                <div className="logo-container">
                    <img src={eligo} alt="" />
                </div>
                <div className="live">
                    <div className="pulse"></div>
                    <span>LIVE</span>
                </div>
            </div>
        </div> */}
        <div className="flex flex-col items-center text-center">
            {!error ?
                <React.Fragment>
                    <p>{t("INSTANT_CALL.WAITING")}</p>
                    <Oval
                        height={80}
                        width={80}
                        color="#d4d4d4"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#000"
                        strokeWidth={2}
                        strokeWidthSecondary={2}

                    />
                    <div className=" font-extralight mt-2 text-2xl ">
                        {`${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`}
                    </div>
                    <Button full onClick={backToHome}>{t("CANCEL")}</Button>
                </React.Fragment>
                : <React.Fragment >
                    <p>{t("INSTANT_CALL.NON_AVAILABLE")}</p>
                    <Button full onClick={bookNow}>{t("BOOK_NOW")}</Button>
                </React.Fragment>
            }
        </div>

    </div >;
}

export default InstantMeeting;