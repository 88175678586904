import React from "react";
import { FaCalendarDay, FaCube, FaRegClock, FaRegUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";
import { setStep } from "../../store/features/booking";
import { createSelector } from "@reduxjs/toolkit";
import { BookingAppointment } from "../../interfaces/booking";
import { t } from "i18next";
import dayjs from "dayjs";
import { RootState } from "../../store";

const getAppointmentTypeText = (id: BookingAppointment) => {
  switch (id) {
    case "online_appointment":
      return "ONLINE";
    case "home_appointment":
      return "HOME";
    case "showroom_appointment":
      return "SHOWROOM";
    default:
      return "APPOINTMENT";
  }
};

const selector = createSelector(
  [
    (state: RootState) => state.booking.appointment,
    (state: RootState) => state.booking.date,
    (state: RootState) => state.booking.time,
  ],
  (appointment, date, time) => ({
    selectedAppointmentType: appointment.id,
    appointmentTypeText: getAppointmentTypeText(appointment.id),
    selectedDate: date,
    selectedTime: time,
  })
);

const WizardStepper: React.FC = () => {
  const dispatch = useDispatch();
  const {
    selectedAppointmentType,
    appointmentTypeText,
    selectedDate,
    selectedTime,
  } = useSelector(selector);
  const { shownTypes } = useSelector((state: RootState) => state.view);
  return (
    <div className="stepper-container">
      {shownTypes.length > 1 ? <Button
        disabled={shownTypes.length === 1}
        className="stepper-btn"
        onClick={() => dispatch(setStep("appointment_type"))}
      >
        <FaCube size={14} />
        <span>{t("APPOINTMENT_TYPE_SELECTION." + appointmentTypeText) as String}</span>
      </Button> : null
      }
      <Button
        className="stepper-btn"
        disabled={selectedAppointmentType === undefined}
        onClick={() => dispatch(setStep("date"))}
      >
        <FaCalendarDay size={14} />
        <span>
          {selectedDate ? dayjs(selectedDate).format("DD/MM/YY") : t("DATE") as String}
        </span>
      </Button>
      <Button
        className="stepper-btn"
        disabled={selectedDate === undefined}
        onClick={() => dispatch(setStep("time"))}
      >
        <FaRegClock size={14} />
        <span>
          {selectedTime ? selectedTime : t("TIME")}
        </span>
      </Button>
      <Button
        className="stepper-btn"
        disabled={selectedTime === undefined}
        onClick={() => dispatch(setStep("info"))}
      >
        <FaRegUser size={14} />
        <span>{t("INFORMATION") as String}</span>
      </Button>
    </div>
  );
};
export default WizardStepper;
