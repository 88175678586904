import React from "react";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import TimeSlotList from "../Time/SlotList";
import { constructDayCycleTimeSlots } from "../../helpers/time";
import { RootState } from "../../store";

const selector = createSelector(
  [
    (state: RootState) => state.booking.time,
    (state: RootState) => state.booking.appointment,
    (state: RootState) => state.booking.date
  ],
  (selectedTime, appointment, date) => ({
    selectedTime,
    date,
    appointmentDuration: appointment.duration,
  })
);

const WizardTimeStep: React.FC = () => {
  const { selectedTime, appointmentDuration, date } = useSelector(selector);
  const { firstSlotDiff } = useSelector((state: RootState) => state.view);
  const dayCycleTimeSlots = React.useMemo(
    () => constructDayCycleTimeSlots(appointmentDuration, firstSlotDiff, date),
    [appointmentDuration, firstSlotDiff, date]
  );

  return (
    <div className="timeslot-list" >
      {dayCycleTimeSlots.map((dcts) => (
        <TimeSlotList
          key={dcts.id}
          dayCycle={dcts.id}
          slots={dcts.slots}
          selectedTime={selectedTime}
        />
      ))}
    </div>
  );
};
export default WizardTimeStep;
