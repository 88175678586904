import { useSelector } from "react-redux";
import { viewPages } from "../constants/view";
import Booking from "../pages/Booking";
import Success from "../pages/Success";
import { RootState } from "../store";

const PageLayout = () => {
    const { page } = useSelector((state: RootState) => state.view);

    return (
        <div className="page">
            {page === viewPages.SUCCESS ?
                <Success /> :
                <Booking />}
        </div>
    );
};

export default PageLayout;
