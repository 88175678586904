import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../components/Page/Title";
import WizardStepper from "../../components/Wizard/Stepper";
import WizardAppointmentTypeStep from "../../components/Wizard/AppointmentTypeStep";
import WizardDateStep from "../../components/Wizard/DateStep";
import WizardTimeStep from "../../components/Wizard/TimeStep";
import WizardInfoStep from "../../components/Wizard/InfoStep";
import { setAppointmentType, setStep } from "../../store/features/booking";
import { BookingStep } from "../../interfaces/booking";
import { ViewState } from "../../interfaces/view";
import { bookingAppointmentTypes } from "../../constants/booking";
import { RootState } from "../../store";

const Booking = () => {
  const dispatch = useDispatch();
  const activeStep: BookingStep = useSelector(
    (state: RootState) => state.booking.step
  );
  const { shownTypes }: ViewState = useSelector(
    (state: RootState) => state.view
  );
  const handleNextStep = React.useCallback(
    (step: BookingStep) => {
      dispatch(setStep(step));
    },
    [dispatch]
  );

  useEffect(() => {
    if (shownTypes.length === 1) {
      let appointmentType = bookingAppointmentTypes.find(({ type }) => type === shownTypes[0]);
      dispatch(setAppointmentType(appointmentType));
      handleNextStep("date");
    }
  }, [shownTypes, dispatch, handleNextStep]);

  return (
    <React.Fragment>
      <PageTitle />

      <WizardStepper />

      {activeStep === "appointment_type" && (
        <WizardAppointmentTypeStep onNextStep={handleNextStep} />
      )}

      {activeStep === "date" && <WizardDateStep onNextStep={handleNextStep} />}

      {activeStep === "time" && <WizardTimeStep />}

      {activeStep === "info" && <WizardInfoStep />}
    </React.Fragment>
  );
};

export default Booking;
