import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { viewPages } from '../constants/view';
import { ViewState } from '../interfaces/view';
import { RootState } from '../store';
import { setPage } from '../store/features/view';
import Button from './Button';
import ImageCarousel from './ImageCarousel';
import tieMasel from "../assets/images/tie-masel.png";
const CallToAction: React.FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { page, toggled }: ViewState = useSelector(
    (state: RootState) => state.view
  );
  const bookNow = (e: React.MouseEvent): void => {
    dispatch(setPage(viewPages.BOOKING));
  }

  const goToLive = (e: React.MouseEvent): void => {
    dispatch(setPage(viewPages.LIVE));
  }

  return (
    <div className={classNames("call-to-action", className)}>
      {page === viewPages.HOME &&
        <React.Fragment>
          <div className="flex-vertical-center">
            <h2 className='text-title'>{t("HELP")}</h2>
            {/* {!toggled && <div className={classNames("live", className)}>
              <div>LIVE</div>
              <div className="pulse secondary"></div>
            </div>
            } */}
          </div>
          <h3 className={classNames("text-subtitle", {})}>{t("BOOK_APPOINTMENT")}</h3>
        </React.Fragment>
      }
      {
        toggled && <div>
          <p>{t("HOME_DESCRIPTION")}</p>
          {/* <ImageCarousel /> */}
          <img src={tieMasel} alt="" />
          <div className='text-center'>
            <Button full style={{ height: "auto", textTransform: "unset" }} onClick={goToLive}>{t("INSTANT_CALL.ENTER")}</Button>
            <div className='text-body uppercase pt-2'>{t("OR")}</div>
            <Button className="secondary" onClick={bookNow} full>{t("BOOK_A_SESSION")}</Button>
          </div>
        </div>
      }
    </div >
  )
}
export default CallToAction;