import dayjs from "dayjs";
import { useDayzed } from "dayzed";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Calendar } from "../../components/Calendar";
import { constructDayCycleTimeSlots } from "../../helpers/time";
import { BookingStep } from "../../interfaces/booking";
import { DatePickerOptions } from "../../interfaces/datepicker";
import { useAppSelector } from "../../store";
import { setDate } from "../../store/features/booking";

// TODO: fix to include today aswell
const Datepicker: React.FC<DatePickerOptions> = ({ selected, onDateSelected, minDate }) => {
  let dayzedData = useDayzed({ selected, onDateSelected, minDate });
  return <Calendar {...dayzedData} />;
};

interface PropTypes {
  onNextStep: (step: BookingStep) => void;
}

const WizardDateStep: React.FC<PropTypes> = ({ onNextStep }) => {
  const dispatch = useDispatch();
  const { date, appointment } = useAppSelector((state) => state.booking);
  const { firstSlotDiff } = useAppSelector((state) => state.view);
  const [minDate, setMinDate] = useState(dayjs().subtract(1, "day").toDate());

  useEffect(() => {
    let today = dayjs().toDate();

    const slots = constructDayCycleTimeSlots(appointment.duration, firstSlotDiff, today);

    if (!slots.flat().map(({ slots }) => slots).flat().length) {
      setMinDate(dayjs().toDate());
    }
  }, [appointment, firstSlotDiff])

  const handleDateSelect = React.useCallback(({ selected, selectable, date }: any) => {
    // let newDate = dayjs(date).add(1, "day");
    // dispatch(setDate(newDate.toDate()));
    dispatch(setDate(date));

    onNextStep("time");
  }, [onNextStep, dispatch]);

  return (
    <div className="flex flex-col mt-2 justify-between">
      <Datepicker
        selected={date || undefined}
        minDate={minDate}
        onDateSelected={handleDateSelect}
      />
    </div>
  );
};
export default WizardDateStep;
