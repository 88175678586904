import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaAngleDown } from "react-icons/fa";
import classnames from "classnames";
import { useResizeDetector } from "react-resize-detector"
import CallToAction from "../components/CallToAction";
import Booking from "../pages/Booking";
import Success from "../pages/Success";
import { setPage, setToggled, setView } from "../store/features/view";
import { viewPages } from "../constants/view";
import { applicationCopyright } from "../constants/appSettings";
import { MutatingDots } from "react-loader-spinner";
import InstantMeeting from "../pages/InstantMeeting";
import { RootState } from "../store";

const WidgetLayout: React.FC = () => {
  const dispatch = useDispatch();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { loading, view, page: activePage, toggled } = useSelector((state: RootState) => state.view);
  const mqttLoading = useSelector((state: RootState) => state.mqtt.loading)
  const { step } = useSelector((state: RootState) => state.booking)
  const { height, ref } = useResizeDetector();

  React.useLayoutEffect(() => {
    const triggerEventResize = () => {
      window.parent.window.postMessage({
        type: "eligo-resize",
        height: ref.current?.offsetHeight,
        width: ref.current?.offsetWidth,
      }, "*");
    }
    let viewVal = window.location.href.match(/view=\w+/g) || [];
    if (viewVal && viewVal[0]) {
      dispatch(setView(viewVal[0].replace("view=", "")));
    }
    const timeout = setTimeout(() => {
      triggerEventResize();
    }, 100);
    return () => clearTimeout(timeout);
  }, [dispatch, activePage, toggled, step, ref.current, containerRef.current, height]);

  const toggleHandler = (init?: boolean) => {
    if (init) {
      dispatch(setPage(viewPages.HOME));

    }
    dispatch(setToggled(!toggled));
    window.parent.window.postMessage({
      type: "eligo-toggle-widget",
      value: !toggled
    }, "*");
  };

  return (
    <div
      ref={ref}
      className={classnames("widget-main-window", {
        "cursor-pointer": !toggled,
        active: toggled,
        [view]: view,
        "pb-[40px]": toggled && activePage !== viewPages.HOME,
      })}
      onClick={!toggled ? () => toggleHandler(false) : () => { }}
    >
      <div ref={containerRef}>
        {
          loading ?
            <div className="eligo-loading__backdrop flex-center left-0 top-0 min-h-full min-w-full absolute z-100 bg-eligo-blue-50 bg-opacity-70" onClick={(ev) => ev.stopPropagation()}>
              <MutatingDots wrapperClass="eligo-loading" height="100px" width="100px" />
            </div> : <></>

        }

        {(activePage === viewPages.HOME || !toggled) && <CallToAction />}
        {
          !mqttLoading && <div
            className={classnames("close-button", { "rotate-180": !toggled })}
            onClick={() => toggleHandler(true)}
          >
            <FaAngleDown size={20} />
          </div>
        }
        {toggled && (
          <React.Fragment>


            {activePage === viewPages.BOOKING && <Booking />}
            {activePage === viewPages.LIVE && <InstantMeeting />}
            {activePage === viewPages.SUCCESS && <Success />}
            <div className="copyright">{applicationCopyright}</div>
          </React.Fragment>
        )}
      </div>

    </div>
  );
};
export default WidgetLayout;
