import React from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import closeIcon from "../../assets/icons/close.svg";
import { MutatingDots } from "react-loader-spinner";
import classNames from "classnames";
import { RootState } from "../../store";
interface PropTypes {
  isShown: boolean;
  headerText?: string;
  onClose: () => void;
  children?: React.ReactNode;
}

const WizardModal: React.FC<PropTypes> = ({
  isShown,
  headerText,
  onClose,
  children,
}) => {

  const { loading, view } = useSelector((state: RootState) => state.view);
  const modal: JSX.Element = (
    <div
      className={classNames("modal", { sm: view === "sm" })}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="modal-backdrop"></div>
      <div className="modal-container">
        {
          loading ?
            <div className="eligo-loading__backdrop">
              <MutatingDots wrapperClass="eligo-loading" height="100px" width="100px" />
            </div>
            : <></>

        }
        <div className="modal-header">
          <h3>
            {headerText}
          </h3>
          <button
            type="button"
            data-modal-toggle="defaultModal"
            onClick={onClose}
          >
            <img style={{ height: "30px", width: "30px", color: "grey" }} alt="close-icon" src={closeIcon} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

export default WizardModal;
